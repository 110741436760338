<template>
  <b-row>
    <b-col sm="12">
      <div class="mb-3">
        {{ property }}
        <b-tabs align="left">
          <b-tab v-for="(tab, index) in tabs" :key="index" :title="tab.title" :active="index == 0" lazy>
            <index-table :role="tab" />
          </b-tab>
        </b-tabs>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'MutewallIndex',
  data() {
    return {
      tabs: [
        { title: 'ผู้ใช้ทั่วไป', role: 'User' },
        { title: 'ซัพพอร์ต', role: 'Support' },
        // { title: 'ครีเอเตอร์', role: 'Partner' }, // ปิดเพราะย้ายไปในหน้าเมนู
        { title: 'แอดมิน', role: 'Admin' },
      ],
    }
  },
  components: {
    IndexTable: () => import('./components/index/IndexTable'),
  },
}
</script>
